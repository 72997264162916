import React, {useEffect} from "react"
import {Link} from "gatsby"
import moment from "moment"
import 'moment/locale/es'

import Image from "../components/image"
import SEO from "../components/seo"
import Layout from "../components/layout";
import BlockContent from "../components/block-content"
import LeftArrow from "../assets/svg/ubuntu-kaleidoscope-arrow.svg"
import RightArrow from "../assets/svg/ubuntu-kaleidoscope-arrow-right.svg"

function Post (props) {
  const
    {post, language} = props.pageContext,
    {title, image, slug, slugEs, date, text, previous, next} = post,
    newDate = moment(date);

    newDate.locale(language)

    useEffect(() => {
      const links = document.querySelectorAll(".article-content a");

      for (let i = 0; i < links.length; i++) {
          links[i].classList.add('clickable');
          links[i].classList.add('underlined');
          links[i].setAttribute("target", "__blank");
      }
    })

  return(
    <Layout>
      <SEO title={title}/>
      <article id="post">
        <aside data-sal="fade" data-sal-delay="250" data-sal-duration="800" data-sal-easing="ease">
          <Image fluid={image.asset.fluid} alt={`${title} | Ubuntu Experience`}/>
        </aside>
        <section>
          <div className="language-links">
            <Link className="clickable" to={`/kaleidoscope/${slug}`}>ENG </Link> /
            <Link className="clickable" to={`/kaleidoscope/${slugEs}`}> ESP</Link>
          </div>
          <h1 data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">{title}</h1>
          <p className="date">{newDate.format('LL')}</p>

          <BlockContent className="article-content" blocks={text || []}/>

          <div className="nav-links">
            <Link className="clickable" to={`/kaleidoscope/${previous !== null ? previous : ''}`}>
              <LeftArrow className="clickable"/> {previous !== null ? "PREVIOUS ARTICLE" : "BACK TO ARTICLES"}
            </Link>
            {next !== null && (
              <Link className="clickable" to={`/kaleidoscope/${next}`}>
                NEXT ARTICLE <RightArrow className="clickable"/>
              </Link>
            )}
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default Post
